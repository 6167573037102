<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">Edit Quote Terms</p>
    </header>
    <section class="modal-card-body">
      <p><quill-editor v-model="terms" :options="quillOptions"></quill-editor></p><br>
    </section>
    <footer class="modal-card-foot modal-footer is-right">
      <button class="button is-light" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary" @click="updateTerms">Update</button>
    </footer>
  </div>
</template>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor
  },
  computed: {
    quillOptions () {
      return {
        modules: {
          toolbar: true
        },
        theme: 'snow'
      }
    }
  },
  data () {
    return {
      terms: null
    }
  },
  methods: {
    updateTerms () {
      this.$emit('update-terms', { terms: this.terms })
      this.$parent.close()
    }
  },
  mounted () {
    this.terms = this.quote.terms
  },
  name: 'QuoteTermsEditor',
  props: ['quote']
}

</script>
