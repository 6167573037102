<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      v-if="quote"
      title="Quote Editor"
      breadcrumb="Quotes"
      subtitle-icon="clipboard"
      :subtitle="subtitle"
      :primary-action="primaryAction"
      :secondary-actions="secondaryActions"
      :tertiary-actions="tertiaryActions"
      v-on:breadcrumb="showQuotes"
      v-on:show-quote-mailer-panel="showQuoteMailer"
      v-on:show-quote-delete-panel="showDeleteQuote"
      v-on:finalize="finalize"
      v-on:unfinalize="unfinalize"
      v-on:download-pdf="downloadPdf"
      v-on:show-terms-editor="showTermsEditor"
      v-on:convert-to-invoice="convertToInvoice"
      v-on:save="save"
    ></subpage-header>
    <!-- for desktop -->
    <div class="columns subpage-information-area-margin is-hidden-touch">
      <div class="column">
        <quote-editor-table v-on:add="showItemEdit" v-on:edit="showItemEdit" :quote="quote"></quote-editor-table>
      </div>
    </div>
    <!-- for mobile -->
    <div class="columns subpage-information-area-margin is-hidden-desktop" style="padding-left: 27px;padding-right: 27px;">
      <div class="column" style="padding-left: 0px; padding-right: 0px;">
        <quote-editor-table v-on:add="showItemEdit" v-on:edit="showItemEdit" :quote="quote"></quote-editor-table>
      </div>
    </div>
  </section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'
import QuoteEditorTable from '@/components/QuoteEditorTable'
import DeleteQuote from '@/components/DeleteQuote'
import SendMail from '@/components/SendMail'
import QuoteItemEditor from '@/components/QuoteItemEditor'
import QuoteTermsEditor from '@/components/QuoteTermsEditor'
import moment from 'moment'

export default {
  name: 'QuoteEdit',
  components: { SubpageHeader, QuoteEditorTable },
  computed: {
    isFinalized () {
      return this.$store.getters['quotes/quoteFinalized']
    },
    isMobile () {
      return this.$isMobile()
    },
    subtitle () {
      if (this.quote.id) {
        return this.quote.quote_number_formatted
      }
      return 'Create new quote.'
    },
    defaultMessageBody () {
      let body = ''
      body += '<p>Here is your quote <strong>' + this.quote.quote_number_formatted + '</strong>. '
      body += 'It is valid until <strong>' + moment(this.quote.expiresAt).format('D MMM YYYY') + '</strong>.</p>'
      return body
    },
    clientContacts () {
      const baseContact = {
        name: this.quote.client.name,
        email: this.quote.client.email
      }
      if (this.quote.client.type === 'business' && this.quote.client.contact_person) {
        baseContact.name = this.quote.client.contact_person
      }
      let clientContacts = [baseContact]
      clientContacts = clientContacts.concat(this.quote.client.additional_contacts)
      return clientContacts
    },
    quote () {
      return this.$store.getters['quotes/quote']
    },
    quoteTaxTotal () {
      return this.$store.getters['quotes/quoteTaxTotal']
    },
    quoteUnpaidTotal () {
      return this.$store.getters['quotes/quoteUnpaidTotal']
    },
    isView () {
      return (!this.enableEditing)
    },
    isEdit () {
      return (this.enableEditing)
    },
    isCreate () {
      if (this.id === 'create') {
        return true
      }
      return false
    },
    primaryAction () {
      if (!this.isFinalized) {
        return {
          name: 'Approve',
          icon: 'check',
          emit: 'finalize'
        }
      }
      return {
        name: 'Send Quote',
        icon: 'paperClip',
        emit: 'show-quote-mailer-panel'
      }
    },
    secondaryActions () {
      const secondaryActions = []
      if (this.isFinalized) {
        secondaryActions.push({
          name: 'Download PDF',
          icon: 'downloadDocument',
          emit: 'download-pdf'
        })
        if (!this.quote.invoice_id) {
          secondaryActions.push({
            name: 'Convert To Invoice',
            link: null,
            icon: 'accounting',
            emit: 'convert-to-invoice'
          })
        }
      }
      if (!this.isFinalized) {
        secondaryActions.push({
          name: 'Save Draft',
          link: null,
          icon: 'alert',
          emit: 'save'
        })
        secondaryActions.push({
          name: 'Terms',
          link: null,
          icon: 'checklist',
          emit: 'show-terms-editor'
        })
      }
      return secondaryActions
    },
    tertiaryActions () {
      const tertiaryActions = []
      if (this.isFinalized) {
        tertiaryActions.push({
          name: 'Unlock Quote',
          icon: 'edit',
          emit: 'unfinalize'
        })
      } else {
        tertiaryActions.push({
          name: 'Delete Quote',
          description: 'Permanently Remove',
          icon: 'bin',
          emit: 'show-quote-delete-panel'
        })
      }
      return tertiaryActions
    }
  },
  methods: {
    downloadPdf () {
      window.open(this.quote.pdf_link)
    },
    showQuotes () {
      this.$router.push({ name: 'quotes' })
    },
    showQuoteMailer () {
      this.$buefy.modal.open({
        parent: this,
        component: SendMail,
        props: {
          title: 'Send Quote ' + this.quote.quote_number_formatted,
          emailAddresses: this.clientContacts,
          defaultMessageBody: this.defaultMessageBody
        },
        fullScreen: this.isMobile,
        events: {
          'send-email': (payload) => {
            payload.quoteId = this.quote.id
            this.sendEmail(payload)
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    showTermsEditor () {
      this.$buefy.modal.open({
        parent: this,
        component: QuoteTermsEditor,
        props: {
          quote: this.quote,
          terms: this.quote.terms
        },
        events: {
          'update-terms': (payload) => {
            this.updateTerms(payload.terms)
          }
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    convertToInvoice () {
      this.$store.dispatch('quotes/convertToInvoice', { quoteId: this.quote.id })
        .then((response) => {
          console.log('response', response)
          this.$buefy.toast.open({
            message: 'Quote converted to invoice.',
            type: 'is-success'
          })
          this.$router.push({ name: 'invoices-show', params: { id: response.data.quote.invoice_id } })
        })
    },
    updateTerms (terms) {
      this.quote.terms = terms
    },
    sendEmail (payload) {
      this.$store.dispatch('quotes/sendQuoteEmail', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Email sent.',
            type: 'is-success'
          })
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.response.data.message}`,
            type: 'is-danger'
          })
        })
    },
    showDeleteQuote () {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteQuote,
        props: {
          quote: this.quote
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    showItemEdit (payload) {
      if (!this.isFinalized) {
        this.$buefy.modal.open({
          parent: this,
          component: QuoteItemEditor,
          props: payload,
          hasModalCard: true,
          trapFocus: true,
          fullScreen: this.isMobile
        })
      }
    },
    finalize () {
      this.quote.finalized = true
      this.save()
    },
    unfinalize () {
      this.quote.finalized = false
      this.save()
    },
    save () {
      this.$store.dispatch('quotes/saveQuote')
        .then((response) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Quote saved.',
            type: 'is-success'
          })
          if (response.isNew) {
            this.$router.push({ name: 'quotes-show', params: { id: response.quote.id } })
          }
        })
        .catch((e) => {
          const errors = e.response.data.errors
          this.isLoading = false
          this.quote.finalized = false
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('taxRates/fetch')
    this.$store.dispatch('clients/fetchAll')
    this.$store.dispatch('user/fetch')
    if (this.id) {
      this.$store.dispatch('quotes/fetchQuote', { quoteId: this.id })
    } else {
      this.$store.dispatch('quotes/resetQuote')
    }
  },
  props: ['id']
}

</script>
