<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">Delete Confirmation</p>
    </header>
    <section class="modal-card-body">
    <p class="modal-text">
      Are you sure you want to <b class="modal-text-notice">delete</b> quote <b>{{ quote.quote_number_formatted }}</b>?<br>
      This can not be undone.
    </p>
    <p class="modal-text">
      <br>
      Confirm by typing <b class="modal-text-notice">{{ quote.quote_number_formatted }}</b> below.
    </p><br>
    <b-field message="Please enter quote number" class="has-padding">
      <b-input :placeholder="quote.quote_number_formatted" type="text" name="subject" v-model="quoteNumberConfirmation" expanded></b-input>
    </b-field>
    </section>
    <footer class="modal-card-foot modal-footer is-right">
      <button class="button is-light" type="button" @click="$parent.close()">Close</button>
      <button class="button is-danger" :disabled="canNotDelete" @click="confirmDelete">Delete Forever</button>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      quoteNumberConfirmation: ''
    }
  },
  computed: {
    canNotDelete () {
      return this.quote.quote_number_formatted !== this.quoteNumberConfirmation || this.quote.finalized === true
    }
  },
  name: 'DeleteQuote',
  methods: {
    confirmDelete () {
      this.$store.dispatch('quotes/deleteQuote', this.quote.id)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Quote ' + this.quote.quote_number_formatted + ' deleted.',
            type: 'is-success'
          })
          this.$parent.close()
          this.$router.push({ name: 'quotes' })
        })
    }
  },
  props: ['quote']
}
</script>

<style>
  .is-right {
    justify-content: flex-end;
  }
  .has-padding {
    padding-left: 10vw;
    padding-right: 10vw;
  }
</style>
