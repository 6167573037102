<template>
  <section>
    <div class="box bunya-box" style="margin-top: 30px;" v-if="quote">
      <br>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <span class="client-name" v-if="quote.created_at">{{ quote.client.name }}</span>
            <b-field label="Client" v-else>
              <b-select placeholder="Select a client" v-model="quote.client_id">
                <option
                  v-for="client in clients"
                  :value="client.id"
                  :key="client.id">
                  {{ client.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="level-right is-hidden-mobile" v-if="brandLogoUrl">
          <div class="level-item">
            <img :src="brandLogoUrl" class="brand-logo">
          </div>
        </div>
      </div>
      <div class="table-controller-area">
        <div class="columns">
          <div class="column is-3">
            <b-field label="Issued">
              <b-datepicker
                v-model="quote.issuedAt"
                placeholder="Billed Date"
                icon="calendar-today"
                trap-focus
                :disabled="isFinalized">
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Expires">
              <b-datepicker
                v-model="quote.expiresAt"
                placeholder="Billed Date"
                icon="calendar-today"
                trap-focus
                :disabled="isFinalized">
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Enter amounts">
              <b-select placeholder="Select Tax Mode" v-model="quote.tax_inclusive" @change.native="updateTaxInclusiveStatus" expanded :disabled="isFinalized">
                <option :value="true">Inclusive Of Tax</option>
                <option :value="false">Exclusive Of Tax</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Status">
              <h5 :class="quoteStatusClass">{{ quote.status }}</h5>
            </b-field>
          </div>
        </div>
      </div>
      <div class="table-controller-area is-hidden">
        <a class="subheader-link">
          <span class="is-inline">
            <icon class="is-flex" icon="copy" width="15" height="19"/><h5>Copy</h5>
          </span>
        </a>
        <a class="subheader-link">
          <span class="is-inline">
            <icon class="is-flex" icon="bin" width="16" height="18"/><h5>Delete</h5>
          </span>
        </a>
      </div>
      <div class="b-table">
        <div class="table-wrapper has-mobile-cards">
          <table class="table is-fullwidth">
            <thead>
            <th>Description</th>
            <th class="has-text-right">Quantity</th>
            <th class="has-text-right">Unit Price</th>
            <th class="has-text-right">Tax Rate</th>
            <th class="has-text-right">Total</th>
            <th class="is-hidden-tablet"></th>
            </thead>
            <tbody>
            <tr
              :class="itemRowClass"
              v-for="(item, index) in quoteItems"
              :key="index"
              @click="$emit('edit', { itemIndex: item.index, taxInclusive: quote.tax_inclusive })"
            >
              <td class="has-text-left is-empty" v-if="item.description">{{ item.description }}</td>
              <td class="has-text-left is-empty" v-if="!item.description">Click to edit.</td>
              <td class="has-text-right" data-label="Quantity">{{ item.quantity }}</td>
              <td class="has-text-right" data-label="Unit Price">{{ item.unit_price | currency }}</td>
              <td class="has-text-right" data-label="Tax Rate">{{ item.tax_rate.name }}</td>
              <td class="has-text-right" data-label="Total Price">{{ item.total_price | currency }}</td>
            </tr>
            </tbody>
            <tfoot>
             <tr class="middle-row">
                <th colspan="3"></th>
                <th class="has-text-right">Subtotal</th>
                <th class="has-text-right">{{ subtotal | currency }}</th>
              </tr>
              <tr class="middle-row">
                <th colspan="3"></th>
                <th class="has-text-right">Tax</th>
                <th class="has-text-right">{{ tax | currency }}</th>
              </tr>
              <tr class="middle-row">
                <th colspan="3"></th>
                <th class="has-text-right"><h4 class="grand-total">Total</h4></th>
                <th class="has-text-right"><h4 class="grand-total">{{ total | currency }}</h4></th>
              </tr>
              <tr class="is-hidden-tablet" v-if="!isFinalized">
                <th colspan="5">
                  <button class="button is-primary is-fullwidth" @click="$emit('add', { itemIndex: null, taxInclusive: quote.tax_inclusive })">Add New Item</button>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";

  .grand-total {
    font-weight: 800;
    color: #000;
  }

  .description {
    text-align: left !important;
  }

  .quote-item-row {
    border: 1px #ccc solid;
    margin-top: 20px;
  }

  .quote-item-row:nth-child(even) {
    background-color: #f9f9f9;
  }

  .quote-item-row:nth-child(odd) {
    background-color: #fff;
  }

  .can-edit.quote-item-row:hover {
    background-color: #dcebf0;
    cursor: pointer;
  }

  .grand-total {
    font-size: 14pt;
    font-weight: 800;
  }

  hr {
    margin-left: -20px;
    margin-right: -20px;
  }

  .client-name {
    color: #424242;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
  }

  .table-controller-area {
    background-color: #fafafa;
    margin: 0 -20px 20px -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #dbdbdb50;
    border-left: none;
    border-right: none;
    display: flex;

    .columns {
      width: 100%;
      padding-left: 20px;
    }

  }

  .is-inline {
    display: flex !important;
    align-items: center;

    .icon-container {
      padding-right: 5px;
    }
  }

  .is-flex {
    display: flex;
  }

  .fake-input-text-right {
    justify-content: right;
  }

  .brand-logo {
    max-width: 200px;
    max-height: 50px;
  }

  .status {
    margin-top: 5px;
    border-radius: 30px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
  }
  .is-invoiced {
    background-color: $success
  }
  .is-rejected {
    background-color: $danger
  }
  .is-accepted {
    background-color: $purple
  }
  .is-issued {
    background-color: $grey
  }
  .is-draft {
    background-color: $light
  }
  .is-emailed {
    background-color: $info
  }

  //@media screen and (max-width: 880px) {
  //.bunya-box { margin-top: 40px !important; }
 // }

</style>

<script>
import Icon from '@/components/Icons/Icon'

export default {
  name: 'EditorTable',
  components: { Icon },
  computed: {
    itemRowClass () {
      return {
        'quote-item-row': true,
        'can-edit': !this.isFinalized
      }
    },
    taxRates () {
      return this.$store.getters['taxRates/taxRates']
    },
    clients () {
      return this.$store.getters['clients/all']
    },
    isFinalized () {
      return this.$store.getters['quotes/quoteFinalized']
    },
    subtotal () {
      return this.$store.getters['quotes/quoteSubtotal']
    },
    tax () {
      return this.$store.getters['quotes/quoteTaxTotal']
    },
    total () {
      return this.$store.getters['quotes/quoteTotal']
    },
    paid () {
      return this.$store.getters['quotes/quotePaidTotal']
    },
    unpaid () {
      return this.$store.getters['quotes/quoteUnpaidTotal']
    },
    quoteItems () {
      return this.$store.getters['quotes/quoteItems']
    },
    brandLogoUrl () {
      return this.$store.getters['account/brandLogoUrl']
    },
    quoteStatusClass () {
      if (!this.quote.id) {
        return {
          status: true,
          'is-draft': true
        }
      }
      return {
        status: true,
        'is-accepted': this.quote.status === 'Accepted',
        'is-draft': this.quote.status === 'Draft',
        'is-emailed': this.quote.status === 'Emailed',
        'is-rejected': this.quote.status === 'Rejected',
        'is-invoiced': this.quote.status === 'Invoiced',
        'is-issued': this.quote.status === 'Issued'
      }
    }
  },
  mounted () {
    this.$store.dispatch('account/fetch')
  },
  methods: {
    updateTaxInclusiveStatus () {
      this.$store.dispatch('quotes/updateTaxInclusiveStatus', this.quote.tax_inclusive)
    }
  },
  props: ['quote']
}
</script>
