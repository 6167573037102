<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <h1 class="header-text">Edit Item {{ item.index }}</h1>
    </header>
    <section class="modal-card-body">
      <div class="columns is-mobile">
        <div class="column is-6 form-field">
          <b-field class="modal-label" label="Quantity">
            <b-input ref="quoteItemEditorQuantity" type="number" :step="0.01" placeholder="0" v-model="item.quantity"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field class="modal-label" label="Unit Price">
            <b-input icon="currency-usd" type="number" :step="0.01" v-model="item.unitPrice"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6 form-field">
          <b-field class="modal-label" label="Tax Rate">
            <b-select placeholder="Tax Rate" required expanded v-model="item.taxRateId">
              <option v-for="(taxRate, index) in taxRates" :key="index" :value="taxRate.id">{{ taxRate.name }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-field class="modal-label" label="Item Description">
            <b-input placeholder="Enter an item description" type="textarea" :rows="2" v-model="item.description"
                     expanded></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-2">
          <button class="button is-light has-icon" @click="deleteItem">
            <icon icon="bin" stroke="#FFFFFF" :width="20" :height="20"/>
          </button>
        </div>
        <div class="column is-9 is-offset-1 has-text-right">
          <h4 class="item-subtotal">{{ totalIncTax | currency }}</h4>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-light" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary" @click="saveItem" :disabled="isSaveDisabled">Save</button>
    </footer>
  </div>
</template>

<style lang="scss">

  @import "../scss/_theme-default.scss";

  .green-outline input {
    border: 1px green solid;
  }

  .item-subtotal {
    font-weight: 800;
    font-size: 24pt;
    color: $dark;
    margin-right: 20px;
  }

</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  name: 'QuoteItemEditor',
  data () {
    return {
      item: {
        id: null,
        index: null,
        quantity: 0,
        unitPrice: 0,
        description: '',
        taxRateId: null
      }
    }
  },
  methods: {
    saveItem () {
      const quoteItem = this.item
      quoteItem.taxRate = this.selectedTaxRate
      quoteItem.totalIncTax = this.totalIncTax
      this.$store.dispatch('quotes/updateQuoteItem', {
        index: this.itemIndex,
        item: quoteItem
      })
      this.$parent.close()
    },
    deleteItem () {
      if (this.item.index || this.item.index === 0) {
        this.$store.dispatch('quotes/deleteQuoteItem', this.item.index)
      }
      this.$parent.close()
    }
  },
  computed: {
    isSaveDisabled () {
      if (!this.item.taxRateId) {
        return true
      }
      if (!this.item.quantity) {
        return true
      }
      if (!this.item.unitPrice) {
        return true
      }
      return false
    },
    taxRates () {
      return this.$store.getters['taxRates/taxRates']
    },
    selectedTaxRate () {
      const taxRateId = this.item.taxRateId
      const selectedTaxRates = this.taxRates.filter((taxRate) => {
        return (taxRate.id === taxRateId)
      })
      if (selectedTaxRates.length === 1) {
        return selectedTaxRates[0]
      }
      return null
    },
    tax () {
      if (!this.selectedTaxRate) {
        return 0
      }
      return this.subtotal * this.selectedTaxRate.rate
    },
    subtotal () {
      if (!this.item.quantity) {
        return 0
      }
      if (!this.item.unitPrice) {
        return 0
      }
      return this.item.quantity * this.item.unitPrice
    },
    totalIncTax () {
      if (!this.selectedTaxRate) {
        return this.subtotal
      }
      if (this.taxInclusive || this.selectedTaxRate === null) {
        return this.subtotal.toFixed(2)
      } else {
        return this.subtotal * (1 + this.selectedTaxRate.rate).toFixed(2)
      }
    }
  },
  mounted () {
    this.$store.dispatch('taxRates/fetch')
    if (this.itemIndex !== null) {
      const storeItem = this.$store.getters['quotes/quoteItem'](this.itemIndex)
      if (storeItem) {
        this.item.id = storeItem.id
        this.item.index = storeItem.index
        this.item.description = storeItem.description
        this.item.quantity = storeItem.quantity
        this.item.taxRateId = storeItem.tax_rate.id
        this.item.unitPrice = storeItem.unit_price
      }
    }
  },
  props: ['itemIndex', 'taxInclusive'],
  components: { Icon }
}
</script>
